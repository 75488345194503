import { Configuration } from './config.types';

import appConfig from './config.app.json';
import uusikaupunkiConfig from './config.uusikaupunki.json';
import riihimakiConfig from './config.riihimaki.json';
import {Camera, Rectangle} from "cesium";

const configuration: Configuration = riihimakiConfig as Configuration;

const getRectangle = () => {
    if(!configuration?.limits?.cartographicLimit) {
        return Rectangle.MAX_VALUE;
    }
    const {longitude, latitude} = configuration.initial;
    const delta = configuration.limits.cartographicLimit;

    return Rectangle.fromDegrees(
        (longitude-delta),
        (latitude-2*delta),
        (longitude+delta),
        (latitude+2*delta));
};

export const cartographicLimitRectangle = getRectangle();

Camera.DEFAULT_VIEW_RECTANGLE = cartographicLimitRectangle;
Camera.DEFAULT_VIEW_FACTOR = -0.01;

export default configuration;