import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Urheilutalo.css';

const Urheilutalo: React.FC = () => {
  return (
      <IonPage>
          <IonHeader>
              <IonToolbar>
                  <IonTitle>Urheilutalo</IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent fullscreen={true}>
              <iframe title={'urheilutalo'} className={'webPage'} src={"https://cloud.zerogravity.fi/shiny/riihimaki/urheilutalo/"}/>
          </IonContent>
      </IonPage>
  );
};

export default Urheilutalo;
