import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Uramonkoulu.css';

const Uramonkoulu: React.FC = () => {
  return (
      <IonPage>
          <IonHeader>
              <IonToolbar>
                  <IonTitle>Uramonkoulu</IonTitle>
              </IonToolbar>
          </IonHeader>
          <IonContent fullscreen={true}>
              <iframe title={'uramonkoulu'} className={'webPage'} src={"https://cloud.zerogravity.fi/shiny/riihimaki/uramonkoulu/"}/>
          </IonContent>
      </IonPage>
  );
};

export default Uramonkoulu;
