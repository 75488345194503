import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {earth, schoolOutline, footballOutline} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import Globe from "./pages/globe/Globe";
import Uramonkoulu from './pages/Uramonkoulu';
import Urheilutalo from './pages/Urheilutalo';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter basename={'/webapp'}>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/globe" component={Globe} exact={true} />
            <Route path="/uramonkoulu" component={Uramonkoulu} exact={true} />
            <Route path="/urheilutalo" component={Urheilutalo} exact={true} />
            <Route path="/" render={() => <Redirect to="/globe" />} exact={true} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/globe">
              <IonIcon icon={earth} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                Map
              </IonLabel>
            </IonTabButton>
            <IonTabButton tab="uramonkoulu" href="/uramonkoulu">
            <IonIcon icon={schoolOutline} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                Uramonkoulu
              </IonLabel>
            </IonTabButton>
            <IonTabButton tab="urheilutalo" href="/urheilutalo">
              <IonIcon icon={footballOutline} />
              <IonLabel
                className="ion-text-capitalize ion-text-center"
              >
                Urheilutalo
              </IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
